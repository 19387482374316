/* eslint-disable react/no-danger */
import React from "react";
import { getIconsBundle, hasIconsBundleBeenRegistered } from "@teliads/icons";

import styles from "./b2x-telia-icon.module.scss";
import { CreateSvg, TeliaIconProps } from "./types";

export type { IconName } from "@teliads/icons/dist/main/Icons";

// Create string from array of attributes
export const getAttributesString = (attributeCollection: Record<string, unknown>[]) =>
  attributeCollection
    .filter((attr) => Object.keys(attr).length >= 1) // remove empty object
    .map((item) => `${item.name}="${item.value}"`)
    .join(" ");

export const createSvg = ({ content = "", title, ariaHidden }: CreateSvg = {}) => {
  const attributeCollection = [
    { name: "xmlns", value: "http://www.w3.org/2000/svg" },
    { name: "fill", value: "currentColor" },
    { name: "viewBox", value: "0 0 64 64" },
    title ? { name: "role", value: "img" } : { name: "aria-hidden", value: "true" },
    ariaHidden ? { name: "aria-hidden", value: "true" } : {},
  ];

  const titleElement = title ? `<title>${title}</title>` : "";
  return `<svg ${getAttributesString(attributeCollection)}>${titleElement}${content}</svg>`;
};

// Remove svg tags
export const getSvgContent = (svgString: string): string => {
  return svgString.replace(/<(\/?)svg([^>]*)>/g, "").trim();
};

export const getSvgString = (svg: string | undefined, name: string | undefined) => {
  if (svg) {
    return svg;
  }

  if (hasIconsBundleBeenRegistered() && name) {
    const icons = getIconsBundle();
    return icons[name] ? icons[name].svg : "";
  }

  if (name) {
    // eslint-disable-next-line no-console
    console.warn(
      name,
      "Icons bundle is not registered and will not be rendered. Check if you have registered icons bundle or use svg property"
    );
  }

  return "";
};

export const TeliaIcon = ({
  allyTitle = "",
  ariaHidden,
  className,
  dataTestid = "",
  name,
  size = "md",
  svg,
}: TeliaIconProps) => {
  const svgIconString = getSvgString(svg, name);
  const svgString = createSvg({
    content: getSvgContent(svgIconString),
    title: allyTitle,
    ariaHidden,
  });

  return (
    <div
      data-testid={dataTestid}
      className={`${className} ${styles["b2x-telia-icon"]} ${styles[`b2x-telia-icon--${size}`]}`}
    >
      <span
        aria-label={allyTitle}
        className="telia-icon__container"
        dangerouslySetInnerHTML={{ __html: svgString }}
      />
    </div>
  );
};
